import { graphQLClient } from '@/client/services/api/clients/graphqlClient';
import {
  StripeInitiateAccount,
  StripeInitiatePaymentMethodCreation,
} from '@/client/services/api/graphql/gql/graphql';
import {
  initiatePaymentMethod,
  stripeLinkAccount,
  initiateStripeAccountSetup,
  getStripeAccountNumber,
  stripeGetLastCustomerPaymentMethod,
  stripeSubmitOneTimePayment,
  stripeCancelSubscription,
  createSubscriptionMutation,
  updateSubscriptionPaymentMethod as updateSubscriptionPaymentMethodMutation,
} from '@/client/services/api/graphql/queries/stripe';

export async function initiateStripeAccount(): Promise<StripeInitiateAccount> {
  const data: any = await graphQLClient.request(initiateStripeAccountSetup, {});
  return data.stripeInitiateAccount;
}

export async function linkStripeAccount(
  account: string,
  verificationToken: string,
): Promise<Boolean> {
  const data: any = await graphQLClient.request(stripeLinkAccount, { account, verificationToken });
  return data.stripeLinkAccount;
}

export async function initiatePaymentMethodSetup(): Promise<StripeInitiatePaymentMethodCreation> {
  const data: any = await graphQLClient.request(initiatePaymentMethod, {});
  return data.stripeInitiatePaymentMethod;
}

export async function getStripeAccount() {
  const data: {companyIntegrations: any} = await graphQLClient.request(getStripeAccountNumber, {});
  return data.companyIntegrations.stripe.account;
}

export async function getStripeAccountLastPaymentMethod() {
  const data: any = await graphQLClient.request(
    stripeGetLastCustomerPaymentMethod,
    {},
  );
  return data.stripeGetLastCustomerPaymentMethod.paymentId;
}

export async function submitOneTimePayment(
  paymentMethodId: string,
  amount: number,
  productId: string
) {
  await graphQLClient.request(stripeSubmitOneTimePayment, {paymentMethodId, amount, productId});
}

export async function createSubscription(
  productId: string
) {
  await graphQLClient.request(createSubscriptionMutation, {productId});
}

export async function cancelSubscription(
  productId: string
) {
  const data: any = await graphQLClient.request(stripeCancelSubscription, {productId});
  return data.cancelSubscription.id;
}

export async function updateSubscriptionPaymentMethod(
  paymentMethodId: string,
  subscriptionId: string
) {
  const data: any = await graphQLClient.request(updateSubscriptionPaymentMethodMutation, {
    paymentMethodId,
    subscriptionId
  });
  return data.updateSubscriptionPaymentMethod.id;
}

const EcommerceService = {
  initiatePaymentMethodSetup,
  initiateStripeAccount,
  linkStripeAccount,
  getStripeAccount,
  getStripeAccountLastPaymentMethod,
  submitOneTimePayment,
  createSubscription,
  cancelSubscription,
  updateSubscriptionPaymentMethod
};

export default EcommerceService;
