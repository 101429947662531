import { useState } from 'react';
import { Button, ButtonGroup, Center, Flex, Text } from '@chakra-ui/react';
import { Action, AuthType, init } from '@thoughtspot/visual-embed-sdk';
import { getAuthToken } from '@/client/services/api/admin/reports/AdvancedReportsService';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useToastStore } from '@/client/services/state/toastStore';
import { useConfigStore } from '@/client/services/state/configStore';
import { AppEmbed, Page } from '@thoughtspot/visual-embed-sdk/react';
import AdminSecondaryHeader from '@/client/components/admin/layout/AdminSecondaryHeader';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from '@/client/components/icons/ContinuIcons';

export default function AdvancedReports() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { allowance } = useAccessHelper();
  const { setToast } = useToastStore();
  const { config } = useConfigStore();

  if (!allowance.advancedReports) {
    setToast({
      show: true,
      status: 'error',
      title: t('advancedReports.noAccess'),
    });
    navigate('/explore');
  }
  useDocumentTitle(t('advancedReports.header'));
  const [isViewingLiveboard, setIsViewingLiveboard] = useState(false);
  init({
    thoughtSpotHost: config.advancedReportsHost,
    authType: AuthType.TrustedAuthTokenCookieless,
    getAuthToken: async () => getAuthToken(),
    disableLoginFailurePage: true,
  });

  const goBack = () => {
    setIsViewingLiveboard(false);
    navigate('/admin/reports/advanced');
  };
  return (
    <>
      <AdminSecondaryHeader>
        <Flex justify="left">
          <Center>
            <ButtonGroup size="xs">
              <Button
                variant="adminPrimary"
                size="xs"
                onClick={() => goBack()}
                leftIcon={<ArrowLeft />}
                isDisabled={!isViewingLiveboard}
              >
                {t('global.actions.back')}
              </Button>
            </ButtonGroup>
          </Center>
          <Text fontSize="xl" lineHeight="1.2" fontWeight="500" margin={2}>
            {t('advancedReports.header')}
          </Text>
        </Flex>
      </AdminSecondaryHeader>
      <AppEmbed
        frameParams={{ height: '100vh' }}
        pageId={Page.Liveboards}
        visibleActions={[]}
        showPrimaryNavbar={false}
        isLiveboardHeaderSticky
        onError={(error) => console.error('Error Loading Advanced Reports', error)}
        onLiveboardRendered={() => {
          setIsViewingLiveboard(true);
        }}
        disabledActions={[Action.Explore]}
        fullHeight
        hideOrgSwitcher
      />
    </>
  );
}
