import { Box, Input, Text, Textarea, VStack } from '@chakra-ui/react';

import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
import ProductTagSearch from '../products/ProductTagSearch';

type DetailsProps = {
  onNextButtonClick: () => void;
};

export default function ProductLandingPageDetails({ onNextButtonClick }: DetailsProps) {
  const { control } = useFormContext();
  const { t } = useTranslation();
  
  return (
    <Box marginBottom={6}>
      <Text fontWeight="500" mb={4}>
        {t('ecommerce.landingPageCreate.header.productLandingPage')}
      </Text>
      <VStack alignItems="start">
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.name')} />
        <Controller
          control={control}
          name="title"
          render={({ field: { onChange, value } }) => (
            <Input
              type="text"
              onChange={onChange}
              value={value}
              placeholder={`${t('ecommerce.landingPageCreate.placeholder.name')}`}
              variant="landingPage"
            />
          )}
        />
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.headline')} />
        <Controller
          control={control}
          name="headline"
          render={({ field: { onChange, value } }) => (
            <Input
              type="text"
              onChange={onChange}
              value={value}
              placeholder={`${t('ecommerce.landingPageCreate.placeholder.headline')}`}
              variant="landingPage"
            />
          )}
        />
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.description')} />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => (
            <Textarea
              onChange={onChange}
              value={value}
              placeholder={`${t('ecommerce.landingPageCreate.placeholder.description')}`}
              variant="landingPage"
              height="140px"
            />
          )}
        />
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.productsListed')} />
        <ProductTagSearch fieldName='products' placeholder={t('ecommerce.landingPageCreate.placeholder.productsListed')}/>
        <SolidButton marginTop={6} alignSelf="flex-end" onClick={() => onNextButtonClick()}>
          {t('button.action.saveAndContinue')}
        </SolidButton>
      </VStack>
    </Box>
  );
}
