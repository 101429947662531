import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import ProductsService from "@/client/services/api/graphql/ProductsService";
import { ShoppingBag } from "@/client/components/icons/ContinuIcons";
import { useFormContext } from "react-hook-form";
import { InputWithTagList } from "@/client/components/forms/registration-forms/InputWithTagList";
import { OrderingDirection, Product } from "@/client/services/api/graphql/gql/graphql";

type ProductTagSearchProps = {
  fieldName: string;
  placeholder: string;
}

export default function ProductTagSearch({ fieldName, placeholder }: ProductTagSearchProps) {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const { setValue, watch } = useFormContext();

  // list products
  const { data: selectedProducts, refetch: refetchSelectedProducts } = useQuery({
    queryKey: ['selected-products', watch(fieldName)],
    queryFn: () =>
      ProductsService.adminListProducts({
        id: { in: watch(fieldName) },
        page: 0,
        pageSize: 20,
        includeTotalCount: false,
        ordering: [{ field: 'purchaseCount', direction: OrderingDirection.Desc }],
      }),
    enabled: !!watch(fieldName)?.length,
  });

  // search products
  const { data: searchedProducts } = useQuery({
    queryKey: ['searched-products', searchTerm],
    queryFn: () =>
      ProductsService.adminListProducts({
        title: { contains: searchTerm },
        page: 0,
        pageSize: 20,
        includeTotalCount: false,
        archived: {eq: false},
        ordering: [{ field: 'updatedAt', direction: OrderingDirection.Desc }],
      }),
    enabled: !!searchTerm?.length,
  });

  const productSelected = (selectedItem: Product) => {
    const currentProducts = watch(fieldName) || [];
    currentProducts.push(selectedItem.id);
    setValue(fieldName, currentProducts);
    refetchSelectedProducts();
  };

  const productDelete = (product: Product) => {
    const newProducts = selectedProducts?.products.filter((filterTag: Product) => filterTag.id !== product.id).map((filteredTag: Product) => filteredTag.id)
    setValue(fieldName, newProducts);
    refetchSelectedProducts();
  };

  const checkIfSelected = (item: Product, selectedItemIds: string[]) => {
    const itemSelected = selectedItemIds?.find(
      (selectedItemId: string) => selectedItemId === item.id,
    );
    return !!itemSelected;
  };

  return (
    <InputWithTagList
      fieldName={fieldName}
      selectedItems={selectedProducts?.products || []}
      selectItem={productSelected}
      deleteItem={productDelete}
      checkIfSelected={checkIfSelected}
      searchTerm={searchTerm}
      inputPlaceholder={placeholder}
      setSearchTerm={setSearchTerm}
      searchedItems={searchedProducts?.products || []}
      ItemsIcon={ShoppingBag}
    />
  )
}
