import { Flex, Stack, Text } from '@chakra-ui/react';

import CreateSwitch from '@/client/components/admin/create/shared/input/CreateSwitch';
import type { DefaultTFuncReturn } from 'i18next';

interface CreateSwitchContainerProps {
  name: string;
  heading?: string | DefaultTFuncReturn;
  headingHelpText?: string | DefaultTFuncReturn;
  label?: string | DefaultTFuncReturn;
  helpText?: string | DefaultTFuncReturn;
}

export default function CreateSwitchContainer({
  name,
  heading,
  headingHelpText,
  label,
  helpText,
}: CreateSwitchContainerProps) {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Stack spacing={0.5}>
        {heading && (
          <>
            <Text variant="createHeading">{heading}</Text>

            {headingHelpText && <Text variant="createHelpText">{headingHelpText}</Text>}
          </>
        )}

        {label && <Text variant="createLabel">{label}</Text>}

        {helpText && <Text variant="createHelpText">{helpText}</Text>}
      </Stack>

      <CreateSwitch name={name} />
    </Flex>
  );
}

CreateSwitchContainer.defaultProps = {
  heading: null,
  headingHelpText: null,
  label: null,
  helpText: null,
};
