import { differenceInDays, isValid } from 'date-fns';

import { DashboardWorkshop } from '@/client/types/DashboardWorkshop';
import { Registration } from '@/client/types/content/ImportedContent';
import { Registration as ScormRegistration } from '@/client/types/content/scorm/Scorm';
import { UserCertificate } from '@/client/types/content/UserCertificate';
import { learnApiClient } from '@/client/services/api/clients/learnApiClient';

interface UserTrack {
  _id: string;
  completed_date: string;
}

export interface CombinedCourse {
  content_id: string;
  id: string;
  content_title: string;
  type: string;
  imageUrl: string;
  created: string;
  completed: boolean;
}

const getCompletedUserTracks = async (
  id: string,
): Promise<{ complete: number; incomplete: number }> => {
  const response = await learnApiClient.get<UserTrack[]>(
    `user-tracks?fields=completed_date&user=${id}`,
  );

  let complete = 0;
  let incomplete = 0;

  response.data.forEach((track: UserTrack) => {
    track.completed_date ? (complete += 1) : (incomplete += 1);
  });

  return {
    complete,
    incomplete,
  };
};

const getAllUserTracks = async (id: string) => {
  const response = await learnApiClient.get(
    `user-tracks?fields=track,completed_articles,completed_media,completed_quizzes,completed_assessments,completed_scorm,completed_date,updatedAt&track-populate=creator_name,name,description,sections.courses,topic,banner_image,archived&user=${id}`,
  );

  return response.data;
};

const getUserCertificates = async (id: string): Promise<UserCertificate[]> => {
  const response = await learnApiClient.get(`certificates/${id}`);

  const withValidDate = response.data.filter((certificate: any) => {
    if (!certificate.completed_date) return false;

    return isValid(new Date(certificate.completed_date));
  });

  return withValidDate.map((certificate: any): UserCertificate => {
    const userCertificate: UserCertificate = {
      certificate: certificate.certificate,
      completedDate: new Date(certificate.completed_date),
      title: certificate.title,
      metadata: certificate.metadata,
      certificateData: certificate.certificate_data,
    };
    return userCertificate;
  });
};

const getUserExternalCourses = async (
  id: string,
): Promise<{ open: CombinedCourse[]; completed: CombinedCourse[] }> => {
  const [scormResponse, icResponse] = await Promise.all([
    learnApiClient.get<ScormRegistration[]>(
      `scorm/registrations/all?sort=%7B%22createdAt%22:-1%7D&users=${id}`,
    ),
    learnApiClient.get<Registration[]>(
      `imported-content/registrations/all?fields%3Dcompany%2Cuser%2Ccontent%2Cresults%2CcreatedAt%2Cstatus&sort=%7B%22createdAt%22:-1%7D&user=${id}&content-populate=title%2Cdescription%2Cvendor%2Cimage%2Cbanner_image%2Carchived%2CcreatedAt`,
    ),
  ]);

  const combinedData: any[] = [];

  scormResponse.data.forEach((item: ScormRegistration) => {
    combinedData.push({
      content_id: item.content._id,
      id: item.content._id,
      content_title: item.content.title,
      type: 'scorm',
      imageUrl: item.content.banner_image,
      created: item.created_at,
      completed: item.status === 'completed' || item.status === 'passed',
      user: item.user,
    });
  });

  icResponse.data.forEach((item: Registration) => {
    combinedData.push({
      content_id: item.content._id,
      id: item.content._id,
      content_title: item.content.title,
      type: 'imported_content',
      imageUrl: item.content.banner_image,
      created: item.createdAt,
      completed: item.completed,
      user: item.user,
    });
  });

  combinedData.sort((a: CombinedCourse, b: CombinedCourse) => {
    const aDate: any = new Date(a.created);
    const bDate: any = new Date(b.created);
    return aDate - bDate;
  });

  return {
    open: combinedData.filter((item) => !item.completed),
    completed: combinedData.filter((item) => item.completed),
  };
};

const getUserWorkshops = async (
  id: string,
): Promise<{
  attended: DashboardWorkshop[];
  registered: DashboardWorkshop[];
}> => {
  const response = await learnApiClient.get<DashboardWorkshop[]>(
    `user-workshops?active_status=$in,2,registered,attended&fields=user,active_status,workshop,workshop_instance&user=${id}&workshop-populate=title,image,type,banner_image&workshop_instance-populate=start_date,end_date,location,online,online_location,online_location_label,recordings`,
  );

  const attended: DashboardWorkshop[] = [];
  const registered: DashboardWorkshop[] = [];

  const sorted = response.data.sort((a: any, b: any) => {
    if (
      !a.workshop_instance ||
      !a.workshop_instance.start_date ||
      !b.workshop_instance ||
      !b.workshop_instance.start_date
    ) {
      return 0;
    }
    if (new Date(a.workshop_instance.start_date) < new Date(b.workshop_instance.start_date)) {
      return 1;
    }
    if (new Date(a.workshop_instance.start_date) > new Date(b.workshop_instance.start_date)) {
      return -1;
    }
    return 0;
  });

  sorted.forEach((item: any) => {
    if (item.active_status === 'attended') {
      attended.push(item);
      return;
    }
    if (item.active_status === 'registered') {
      const start = (item.workshop_instance && item.workshop_instance.start_date) || null;
      if (start && new Date(start) < new Date()) {
        attended.push(item);
        return;
      }
      registered.unshift(item);
    }
  });

  return {
    attended,
    registered,
  };
};

const getUserAssignments = async (id: string) => {
  const response = await learnApiClient.get(
    `assignments?article-populate=title,content,banner_image,archived&assessment-populate=title,description,archived&assigned_content.content_id-populate=title,name,description,banner_image,archived,type,image&assignee=${id}&fields=assignee,completed,completed_date,completed_content,assigner,article,media,track,quiz,scorm,imported_content,assessment,createdAt,assigned_content.content_id,assigned_content.content_type,due_date,assigned_content.user_content_id, assigned_content.user_content_type&imported_content-populate=title,description,banner_image,archived&media-populate=title,type,description,image,archived&quiz-populate=title,description,banner_image,archived&scorm-populate=title,description,banner_image,image,archived&sort=createdAt,-1&track-populate=name,description,banner_image,archived`,
  );
  const processedData = response.data.filter((assignment: any) =>
    assignment.assigned_content.some((content: any) => content.content_id.archived === false),
  );
  const completedAssignments = processedData.filter((assignment: any) => assignment.completed);
  const openAssignments = processedData.filter((assignment: any) => !assignment.completed);

  let completedLastThirtyCount = 0;

  processedData.forEach((assignment: any) => {
    if (assignment.completed) {
      const completedDate = new Date(assignment.completed_date);
      const today = new Date();
      const diff = differenceInDays(today, completedDate);

      if (diff <= 30) completedLastThirtyCount += 1;
    }
  });

  return {
    assignments: processedData,
    completedAssignments,
    openAssignments,
    completedLastThirtyCount,
  };
};

const DashboardService = {
  getCompletedUserTracks,
  getAllUserTracks,
  getUserCertificates,
  getUserExternalCourses,
  getUserWorkshops,
  getUserAssignments,
};

export default DashboardService;
