import SolidButton from '@/client/components/shared/buttons/SolidButton';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface CreatePublishButtonProps {
  isDisabled?: boolean;
  onClick: () => void;
}

export default function CreatePublishButton({ isDisabled, onClick }: CreatePublishButtonProps) {
  const { id } = useParams();
  const { t } = useTranslation();

  const isUpdating = !!id;

  return (
    <SolidButton isDisabled={isDisabled} onClick={onClick}>
      {isUpdating ? t('authoring.update') : t('authoring.publish')}
    </SolidButton>
  );
}

CreatePublishButton.defaultProps = {
  isDisabled: false,
};
