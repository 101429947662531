import { Box, FormLabel, Grid, GridItem, Radio, RadioGroup } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import AdminElevatedBox from '../../layout/AdminElevatedBox';
import AdminUsersService from '@/client/services/api/admin/users/AdminUsersService';
import GroupsService from '../../../../services/api/admin/connect/GroupsService';
import HeadingWithTooltip from '../HeadingWithTooltip';
import PartnersService from '@/client/services/api/admin/PartnersService';
import PeopleService from '../../../../services/api/PeopleService';
import { Person } from '../../../../types/Person';
import ProfilesService from '../../../../services/api/admin/ProfilesService';
import SearchSelect from '../../../input/SearchSelect';
import SearchableDropdownInput from '../../../input/SearchableDropdownInput';
import { getSupportedLanguages } from '../../../../utils/getSupportedLanguages';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useAuthStore } from '../../../../services/state/authStore';
import { usePartnerStore } from '@/client/services/state/partnerStore';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

interface Option {
  label: string;
  value: string;
  image?: string;
}

interface GridInputData {
  isMultiple: boolean;
  name: string;
  label: string;
  helpText: string;
  isRequired: boolean;
  enabled: boolean;
}

interface GridInputSelect extends GridInputData {
  inputType: 'select';
  placeholder: string;
  options: { value: string; label: string; enabled?: boolean }[];
}

interface GridInputSelectMulti extends GridInputData {
  inputType: 'select';
  placeholder: string;
  options: { value: string; label: string; enabled?: boolean }[];
}

interface GridInputRadio extends GridInputData {
  inputType: 'radio';
}

interface GridInputSearch extends GridInputData {
  inputType: 'search';
  placeholder: string;
  loadOptions: (value: string) => void;
  defaultValue: Option | undefined;
}

type GridInput = GridInputSelect | GridInputSelectMulti | GridInputRadio | GridInputSearch;

export default function UserDetails() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { partner } = usePartnerStore();
  const { isAllowed } = useAccessHelper();
  const [managerQueryEnabled, setManagerQueryEnabled] = useState<boolean>(true);
  const [buddyQueryEnabled, setBuddyQueryEnabled] = useState<boolean>(true);

  const allowance = {
    profile_set: isAllowed(['admin', 'users', 'profile_set'], null, [
      'admin',
      'creator',
      'manager',
    ]),
    manager_set: isAllowed(['admin', 'users', 'manager_set'], null, [
      'admin',
      'creator',
      'manager',
    ]),
    purchaser_set: company.feature_flags.enable_ecommerce,
    buddy_set: isAllowed(['admin', 'users', 'buddy_set'], null, ['admin', 'creator', 'manager']),
  };

  const { formState, control, watch } = useFormContext();

  const initialManagerEmail = useWatch({ control, name: 'manager_email' });
  const initialBuddyEmail = useWatch({ control, name: 'buddy_email' });

  // profile options
  const { data: profiles } = useQuery({
    queryKey: ['profiles'],
    queryFn: () => ProfilesService.getAllProfiles(),
    select: (data) => {
      const formattedProfiles = data.map((profile) => ({
        value: profile._id,
        label: profile.name,
      }));

      formattedProfiles.unshift({ value: 'remove', label: 'No Profile' });

      return formattedProfiles;
    },
  });

  // partner options
  const { data: partners } = useQuery({
    queryKey: ['partners', partner._id],
    enabled: company.feature_flags.enable_partners && !partner._id,
    queryFn: () => PartnersService.getAllPartners({ pageIndex: 0, pageSize: undefined }),
    select: (data) => {
      const formattedPartners = data.rows.map((foundPartner) => ({
        value: foundPartner._id,
        label: foundPartner.name,
      }));

      formattedPartners.unshift({ value: '', label: 'No Partner' });

      return formattedPartners;
    },
  });

  // managing group options
  const { data: groups } = useQuery({
    queryKey: ['managing-groups'],
    queryFn: () => GroupsService.getAllGroups(),
    select: (data) => {
      const formattedGroups = data.map((group) => ({ value: group._id, label: group.name }));

      return formattedGroups;
    },
  });

  // Get and set manager and buddy based on email
  const { data: manager } = useQuery({
    queryKey: ['manager-email', initialManagerEmail],
    enabled: !!initialManagerEmail?.length && managerQueryEnabled,
    queryFn: () => {
      const queryString = `email=${encodeURIComponent(initialManagerEmail)}`;
      return PeopleService.searchWithParamsInfinite(1, queryString);
    },
    select: (data) => {
      const formattedManager = {
        value: data[0].email,
        label: data[0].full_name,
        image: data[0].image,
      };

      return formattedManager;
    },
  });

  const { data: buddy } = useQuery({
    queryKey: ['buddy-email', initialBuddyEmail],
    enabled: !!initialBuddyEmail?.length && buddyQueryEnabled,
    queryFn: () => {
      const queryString = `email=${encodeURIComponent(initialBuddyEmail)}`;
      return PeopleService.searchWithParamsInfinite(1, queryString);
    },
    select: (data) => {
      const formattedBuddy = {
        value: data[0].email,
        label: data[0].full_name,
        image: data[0].image,
      };

      return formattedBuddy;
    },
  });

  // Keep queries from running after initialization
  useEffect(() => {
    if (manager) {
      setManagerQueryEnabled(false);
    }
  }, [manager]);

  useEffect(() => {
    if (buddy) {
      setBuddyQueryEnabled(false);
    }
  }, [buddy]);

  // Assigning buddy with async select
  const searchForUsers = async (name: string, onlyManagers?: boolean) => {
    const queryString = onlyManagers
      ? `full_name=$regex,2,${name},i&email=$regex,2,${name},i&is_manager=true&`
      : `email=$regex,2,${name},i&full_name=$regex,2,${name},i&`;
    const people = await PeopleService.searchWithParamsInfinite(1, queryString);

    const removal = {
      value: null,
      label: t('global.forms.labels_none'),
      image: '',
    };
    const managers = [
      removal,
      ...people.map((person: Person) => ({
        value: person.email,
        label: person.full_name,
        image: person.image,
      })),
    ];

    return managers;
  };

  // Assigning manager with async select
  const searchForManagers = async (name: string) => {
    const managers = await AdminUsersService.searchManagers(name);

    const removal = {
      value: null,
      label: t('global.forms.labels_none'),
      image: '',
    };

    return [
      removal,
      ...managers.map((m: Person) => ({
        value: m.email,
        label: m.full_name,
        image: m.image,
      })),
    ];
  };

  // Languages
  const companyLanguages = company.available_languages;
  const supportedLanguages = getSupportedLanguages();

  const availableLanguages = supportedLanguages
    .filter((language) => {
      if (companyLanguages.includes(language.value)) {
        return true;
      }
      if (language.value === 'en' && companyLanguages.includes('eng')) {
        return true;
      }
      return false;
    })
    .map((language) => ({ value: language.value, label: t(language.name) }));

  // Roles
  const userRoles = [
    {
      label: t('manage.users.administrator'),
      value: 'admin',
      enabled: !watch('partner')?.length,
    },
    {
      label: t('manage.users.creator'),
      value: 'creator',
      enabled: !watch('partner')?.length,
    },
    {
      label: t('manage.users.user'),
      value: 'user',
    },
    {
      label: t('manage.users.external'),
      value: 'external',
      enabled: !watch('partner')?.length,
    },
  ];

  // Partner Roles
  const partnerRoles = [
    {
      label: t('manage.users.user'),
      value: 'user',
    },
    {
      label: t('manage.users.partner.principal'),
      value: 'principal',
    },
  ];

  // Format data to work with react select on page render

  const inputs = useMemo<GridInput[]>(
    () => [
      {
        inputType: 'select',
        isMultiple: false,
        name: 'role',
        enabled: !partner._id,
        label: t('manage.users.userType'),
        placeholder: t('manage.users.placeholder.role'),
        helpText: '',
        options: userRoles,
        isRequired: true,
      },
      {
        inputType: 'select',
        isMultiple: false,
        name: 'access_profile',
        enabled: !partner._id && allowance.profile_set,
        label: t('overview.settings.access_profiles'),
        placeholder: t('manage.users.placeholder.profiles'),
        helpText: '',
        options: profiles || [],
        isRequired: false,
      },
      {
        inputType: 'select',
        isMultiple: false,
        name: 'language',
        enabled: true,
        label: t('manage.users.language'),
        placeholder: t('manage.users.placeholder.languages'),
        helpText: '',
        options: availableLanguages,
        isRequired: false,
      },
      {
        inputType: 'radio',
        isMultiple: false,
        name: 'is_manager',
        enabled: !partner._id && allowance.manager_set,
        label: t('edit.useradd.is_manager'),
        helpText: '',
        isRequired: false,
      },
      {
        inputType: 'search',
        isMultiple: false,
        name: 'manager_email',
        enabled: !partner._id,
        label: t('userEditor.user_reports_to'),
        placeholder: t('manage.users.placeholder.manager'),
        helpText: '',
        loadOptions: (query: string) => searchForManagers(query),
        isRequired: false,
        defaultValue: manager,
      },
      {
        inputType: 'radio',
        isMultiple: false,
        name: 'is_collaborator',
        enabled: !partner._id,
        label: t('userEditor.collaborator'),
        helpText: '',
        isRequired: false,
      },
      {
        inputType: 'radio',
        isMultiple: false,
        name: 'is_purchaser',
        enabled: allowance.purchaser_set,
        label: t('userEditor.purchaser'),
        helpText: '',
        isRequired: false,
      },
      {
        inputType: 'search',
        isMultiple: false,
        name: 'buddy_email',
        enabled: !partner._id && allowance.buddy_set,
        label: t('userEditor.assign_buddy'),
        placeholder: t('manage.users.placeholder.buddy'),
        helpText: '',
        loadOptions: (query: string) => searchForUsers(query),
        isRequired: false,
        defaultValue: buddy,
      },
      {
        inputType: 'select',
        isMultiple: false,
        name: 'partner',
        enabled: company.feature_flags.enable_partners && !partner._id && watch('role') === 'user',
        label: t('userManage.partner'),
        placeholder: t('userManage.placeholder.partner'),
        helpText: '',
        options: partners || [],
        isRequired: false,
      },
      {
        inputType: 'select',
        isMultiple: false,
        name: 'partner_role',
        enabled: company.feature_flags.enable_partners && watch('partner')?.length,
        label: t('userManage.partnerRole'),
        placeholder: t('userManage.placeholder.partnerRole'),
        helpText: '',
        options: partnerRoles || [],
        isRequired: false,
      },
      {
        inputType: 'select',
        isMultiple: true,
        name: 'managing_groups',
        enabled: !partner._id,
        label: t('addGroupManager.managingGroups'),
        placeholder: t('manage.users.placeholder.managingGroups'),
        helpText: '',
        options: groups || [],
        isRequired: false,
      },
    ],
    [formState, manager, buddy, groups],
  );

  return (
    <AdminElevatedBox>
      <HeadingWithTooltip
        title={t('edit.useradd.tab.userDetails')}
        helpText={t('registrationForms.tooltip.segmentation')}
      />

      <Grid
        h="auto"
        templateRows="repeat(0.75, 32%)"
        templateColumns="repeat(3, 1fr)"
        gap={4}
        rowGap={6}
        marginY={4}
        marginLeft={2}
      >
        {inputs.map((input: GridInput) => {
          if (!input.enabled) {
            return;
          }
          if (input.inputType === 'select') {
            return (
              <GridItem key={input.name} colSpan={1} rowSpan={1}>
                <SearchableDropdownInput
                  name={input.name}
                  label={input.label}
                  tooltipText={input.helpText}
                  placeholder={input.placeholder}
                  options={input.options.filter(
                    (option) => !Object.hasOwn(option, 'enabled') || option.enabled,
                  )}
                  isRequired={input.isRequired}
                  isMultiple={input.isMultiple}
                />
              </GridItem>
            );
          }
          if (input.inputType === 'radio') {
            return (
              <GridItem key={input.name} colSpan={1} rowSpan={1}>
                <FormLabel mb={2} fontSize="14px" fontWeight="bold">
                  {input.label}
                </FormLabel>
                <Controller
                  control={control}
                  name={input.name}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      variant="admin"
                      value={(value && value.toString()) || 'false'}
                      onChange={(v) => onChange(v === 'true')}
                    >
                      <Radio value="true" marginRight={6}>
                        {t('global.forms.labels_yes')}{' '}
                      </Radio>
                      <Radio value="false">{t('global.forms.labels_no')}</Radio>
                    </RadioGroup>
                  )}
                />
              </GridItem>
            );
          }
          if (input.inputType === 'search') {
            return (
              <GridItem key={input.name} colSpan={1} rowSpan={1}>
                <SearchSelect
                  label={input.label}
                  name={input.name}
                  includeAvatar
                  loadOptions={input.loadOptions!}
                  defaultValue={input.defaultValue}
                />
              </GridItem>
            );
          }
          return <Box />;
        })}
      </Grid>
    </AdminElevatedBox>
  );
}
