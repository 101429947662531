import { graphQLClient } from "@/client/services/api/clients/graphqlClient";
import {
  getGenerativeKeywords as getGenerativeKeywordsQuery,
  getTextGenerationStatus as getTextGenerationStatusQuery,
  getGenerativeKeywordsById as getGenerativeKeywordsByIdQuery,
  getGenerativeDescriptionById as getGenerativeDescriptionByIdQuery
} from "./queries/generativeAi";
import { TextGenerationStatus } from "@/client/services/api/graphql/gql/graphql";

export async function getGenerativeKeywords(text: string): Promise<string[]> {
    const data: any = await graphQLClient.request(getGenerativeKeywordsQuery, { text });
    return data.generativeKeywords;
}

export async function getGenerativeKeywordsById(id: string): Promise<string[]> {
  const data: any = await graphQLClient.request(
    getGenerativeKeywordsByIdQuery,
    { id }
  );
  return data.generativeKeywordsById;
}

export async function getGenerativeDescriptionById(
  id: string
): Promise<string> {
  const data: any = await graphQLClient.request(
    getGenerativeDescriptionByIdQuery,
    { id }
  );
  return data.generativeDescriptionById;
}

const getTextGenerationStatus = async (
  id: string | undefined,
  type: string,
  userid: string
): Promise<any> => {
  const data: any = await graphQLClient.request(getTextGenerationStatusQuery, {
    id,
    type,
    userid,
  });
  return data.content.currentVersion;
};

const GenerativeService = {
  getGenerativeKeywords,
  getGenerativeKeywordsById,
  getGenerativeDescriptionById,
  getTextGenerationStatus,
};

export default GenerativeService;