import { Elements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useConfigStore } from '@/client/services/state/configStore';
import { Box, Container } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import EcommerceService from '@/client/services/api/graphql/EcommerceService';
import Loading from '@/client/components/media/Loading';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import PaymentMethodForm from './PaymentMethodForm';
import { useGetProduct } from '@/client/services/hooks/content/products/useGetProduct';


export default function MakePaymentWrapper() {
  useDocumentTitle('Manage Payment Methods');
  const { t } = useTranslation();
  const { config } = useConfigStore();
  const CLIENT_URL = window.location.origin;
  const routeParams = useParams();
  const navigate = useNavigate();

  const { data: productData } = useGetProduct(routeParams.id);
  const returnURL = `${CLIENT_URL}/product/${productData?.id}/payment-setup-success`;

   const {
     data: accountData,
   } = useQuery({
     queryKey: ['get-stripe-account'],
     queryFn: () => EcommerceService.getStripeAccount(),
   });
  const accountId: string = accountData;

  const stripePromise = loadStripe(config.stripe.publicKey, {
    stripeAccount: accountId,
  });

  const {
    isLoading,
    data: initiateData,
    error: initiateError,
  } = useQuery({
    queryKey: ['initiate-payment-method-setup'],
    queryFn: () => EcommerceService.initiatePaymentMethodSetup(),
  });

  if (productData?.default) {
    navigate('/explore');
  }

  if (isLoading) {
    return <Loading />;
  }

  if (initiateError) {
    return <ErrorAlert title={t('ecommerce.checkout.errorInitiaiting')} />;
  }

  const { clientSecret } = initiateData!;

  return (
    <Container minWidth="100%" minHeight="100vh" position="relative">
      <Box textAlign="center" fontSize="xl">
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <PaymentMethodForm clientSecret={clientSecret} returnUrl={returnURL} productData={productData} />
        </Elements>
      </Box>
    </Container>
  );
}
