import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from '@stripe/react-stripe-js';
import { useState, useEffect } from 'react';
import { Box, Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'usehooks-ts';
import PaymentSummary from './PaymentSummary';
import { Product } from '@/client/services/api/graphql/gql/graphql';
import { useAuthStore } from '@/client/services/state/authStore';
import ProductsService from '@/client/services/api/graphql/ProductsService';
import AlreadyPurchased from './AlreadyPurchased';

export type Props = {
  clientSecret: string;
  returnUrl: string;
  productData?: Product | null;
};

export default function PaymentMethodForm({
  clientSecret,
  returnUrl,
  productData = undefined,
}: Props) {
  useDocumentTitle('Manage Payment Methods');
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const [isComplete, setIsComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const { data: purchaseData } = useQuery({
    enabled: !!user && !!productData,
    queryKey: ['dashboard-puchases', user._id, productData?.id],
    queryFn: () =>
      ProductsService.listPurchases({
        user: { eq: user._id },
        product: { eq: productData?.id },
        subscriptionActive: true,
        page: 0,
        pageSize: 1,
        includeTotalCount: false,
      }),
  });

  useEffect(() => {
    if (!elements) return;
    const paymentElement = elements.getElement(PaymentElement);
    if (!paymentElement) return;
    // @ts-ignore
    paymentElement.on('change', (event) => {
      setIsComplete(event.complete);
    });
    return () => {
      // @ts-ignore
      paymentElement.off('change');
    };
  }, [elements]);

  const handleAddNewCard = async () => {
    if (!stripe || !elements) return;

    const paymentElementRef = elements.getElement(PaymentElement);
    const addressElementRef = elements.getElement(AddressElement);
    const address = await addressElementRef?.getValue();
    // TODO: Throw error is address not complete
    if (!address?.complete) return;

    if (!paymentElementRef) return;

    try {
      await elements.submit();
      const { error: setupError } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (error) {
        setError(setupError.message as string);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    handleAddNewCard();
  };

  if (purchaseData?.purchases.length) {
    return (
      <AlreadyPurchased purchasedDate={new Date(purchaseData.purchases[0].purchaseConfirmedDate)} />
    );
  }

  return (
    <Grid minH="100vh" templateColumns={productData ? 'repeat(2, 1fr)' : '1fr'}>
      <GridItem>
        {clientSecret && (
          <Flex w="100%" justifyContent="center" paddingTop="100px">
            <form onSubmit={handleSubmit}>
              <Box>
                <Grid gap={2}>
                  <GridItem colSpan={{ base: 12 }}>
                    <Text fontWeight="600" textAlign="left" marginBottom={4}>
                      {t('ecommerce.checkout.billingInformation')}
                    </Text>
                    <Box>
                      <AddressElement
                        options={{
                          mode: 'billing',
                          validation: {
                            phone: {
                              required: 'always',
                            },
                          },
                          fields: {
                            phone: 'always',
                          },
                        }}
                      />
                    </Box>
                  </GridItem>

                  <GridItem colSpan={{ base: 12 }}>
                    <Text variant="label" textAlign="left" marginY={4}>
                      {t('ecommerce.checkout.cardDetails')}
                    </Text>
                    <Box>
                      <PaymentElement />
                    </Box>
                  </GridItem>
                </Grid>
              </Box>
              {error && <p>{error}</p>}
              {!productData && (
                <Button
                  colorScheme="blue"
                  type="submit"
                  isDisabled={!isComplete || isSubmitting}
                  onClick={(event: any) => handleSubmit(event)}
                >
                  {t('ecommerce.checkout.button.completeCheckout')}
                </Button>
              )}
            </form>
          </Flex>
        )}
      </GridItem>
      {!!productData && (
        <GridItem>
          <PaymentSummary
            productInfo={productData}
            handleSubmit={handleSubmit}
            isComplete={isComplete}
            isSubmitting={isSubmitting}
          />
        </GridItem>
      )}
    </Grid>
  );
}
