import { create } from 'zustand';

interface CreateState {
  contentTitle: string;
  setContentTitle: (contentTitle: string) => void;
  contentType: 'article' | 'file' | 'video' | 'scorm' | 'product';
  setContentType: (contentType: 'article' | 'file' | 'video' | 'scorm' | 'product') => void;
  previewFullscreen: boolean;
  setPreviewFullscreen: (previewFullscreen: boolean) => void;
  tabIndex: number;
  setTabIndex: (tabIndex: number) => void;
  canAdvance: boolean;
  setCanAdvance: (canAdvance: boolean) => void;
  duplicateModalIsOpen: boolean;
  setDuplicateModalIsOpen: (duplicateModalIsOpen: boolean) => void;
  discardModalIsOpen: boolean;
  setDiscardModalIsOpen: (discardModalIsOpen: boolean) => void;
  noImageModalIsOpen: boolean;
  setNoImageModalIsOpen: (noImageModalIsOpen: boolean) => void;
  publishModalIsOpen: boolean;
  setPublishModalIsOpen: (publishModalIsOpen: boolean) => void;
  archiveModalIsOpen: boolean;
  setArchiveModalIsOpen: (archiveModalIsOpen: boolean) => void;
  collaboratorSaveModalIsOpen: boolean;
  setCollaboratorSaveModalIsOpen: (collaboratorSaveModalIsOpen: boolean) => void;
  canLeave: boolean;
  setCanLeave: (canLeave: boolean) => void;
  uploadStatus: 'idle' | 'loading' | 'success' | 'error';
  setUploadStatus: (uploadStatus: 'idle' | 'loading' | 'success' | 'error') => void;
  tinyMceInFullscreenMode: boolean;
  setTinyMceInFullscreenMode: (tinyMceInFullscreenMode: boolean) => void;
}

export const useCreateStore = create<CreateState>((set) => ({
  contentTitle: '',
  setContentTitle: (contentTitle) => set({ contentTitle }),
  contentType: 'article',
  setContentType: (contentType) => set({ contentType }),
  previewFullscreen: false,
  setPreviewFullscreen: (previewFullscreen) => set({ previewFullscreen }),
  tabIndex: 0,
  setTabIndex: (tabIndex) => set({ tabIndex }),
  canAdvance: false,
  setCanAdvance: (canAdvance) => set({ canAdvance }),
  duplicateModalIsOpen: false,
  setDuplicateModalIsOpen: (duplicateModalIsOpen) => set({ duplicateModalIsOpen }),
  discardModalIsOpen: false,
  setDiscardModalIsOpen: (discardModalIsOpen) => set({ discardModalIsOpen }),
  noImageModalIsOpen: false,
  setNoImageModalIsOpen: (noImageModalIsOpen) => set({ noImageModalIsOpen }),
  publishModalIsOpen: false,
  setPublishModalIsOpen: (publishModalIsOpen) => set({ publishModalIsOpen }),
  archiveModalIsOpen: false,
  setArchiveModalIsOpen: (archiveModalIsOpen) => set({ archiveModalIsOpen }),
  collaboratorSaveModalIsOpen: false,
  setCollaboratorSaveModalIsOpen: (collaboratorSaveModalIsOpen) =>
    set({ collaboratorSaveModalIsOpen }),
  canLeave: false,
  setCanLeave: (canLeave) => set({ canLeave }),
  uploadStatus: 'idle',
  setUploadStatus: (uploadStatus) => set({ uploadStatus }),
  tinyMceInFullscreenMode: false,
  setTinyMceInFullscreenMode: (tinyMceInFullscreenMode) => set({ tinyMceInFullscreenMode }),
}));
