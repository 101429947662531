import { useNavigate, useParams } from 'react-router-dom';

import ContentAudienceForm from '@/client/components/admin/create/content/forms/audience/ContentAudienceForm';
import ContentPublishForm from '@/client/components/admin/create/content/forms/ContentPublishForm';
import ContentSettingsForm from '@/client/components/admin/create/content/forms/settings/ContentSettingsForm';
import CreateTabs from '@/client/components/admin/create/shared/tabs/CreateTabs';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import Loading from '@/client/components/media/Loading';
import type { ScormFormData } from '@/client/routes/admin/create/content/EditScorm';
import ScormGeneralDetailsForm from '@/client/components/admin/create/content/forms/scorm/ScormGeneralDetailsForm';
import TabControls from '@/client/components/admin/create/content/tabs/TabControls';
import { useAuthStore } from '@/client/services/state/authStore';
import { useAuthorScorm } from '@/client/services/hooks/admin/authoring/useAuthorScorm';
import { useCheckContentEditable } from '@/client/services/hooks/admin/authoring/useCheckContentEditable';
import { useCreateNavigationBlocker } from '@/client/services/hooks/admin/authoring/useCreateNavigationBlocker';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTrackStateStore } from '@/client/services/state/admin/create/trackStateStore';
import { useTranslation } from 'react-i18next';

export default function EditScormForm() {
  useCreateNavigationBlocker();
  const { id } = useParams();
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { t } = useTranslation();
  const { submitScormMutation, getScormForEditor } = useAuthorScorm(id);
  const { watch, formState } = useFormContext<ScormFormData>();
  const { isValid } = formState;
  const { checkContentEditable } = useCheckContentEditable();
  const { trackState, setTrackState } = useTrackStateStore();
  const navigate = useNavigate();
  const {
    tabIndex,
    setTabIndex,
    setNoImageModalIsOpen,
    setContentType,
    uploadStatus,
    setContentTitle,
  } = useCreateStore();

  useEffect(() => {
    setContentType('scorm');
  }, []);

  const { data, isFetching, isError, error } = getScormForEditor;

  const title = watch('title');
  const description = watch('description');
  const scormFileDetails = watch('scorm_file_details');
  const bannerImage = watch('bannerImage');
  const cardImage = watch('image');

  useEffect(() => {
    setContentTitle(title as string);
  }, [title]);

  const canAdvance =
    scormFileDetails !== null && uploadStatus !== 'loading' && description !== '' && title !== '';

  const tabOptions = [
    {
      label: t('authoring.scorm.tabs.scorm'),
      content: <ScormGeneralDetailsForm canAdvance={canAdvance} />,
      isDisabled: false,
    },
    {
      label: t('authoring.tabs.audience'),
      content: <ContentAudienceForm />,
      isDisabled: !isValid,
    },
    {
      label: t('authoring.tabs.settings'),
      content: <ContentSettingsForm isScorm />,
      isDisabled: !isValid,
    },
    {
      label: id ? t('authoring.update') : t('authoring.tabs.publish'),
      content: <ContentPublishForm />,
      isDisabled: !isValid,
    },
  ];

  useEffect(() => checkContentEditable(data), [data]);

  const onProceed = () => {
    if (tabIndex === 0 && !cardImage && !bannerImage && !trackState) {
      setNoImageModalIsOpen(true);
      return;
    }

    setTabIndex(tabIndex + 1);
  };

  const handlePublish = () =>
    submitScormMutation
      .mutateAsync({
        approval_required: user.is_collaborator,
        approved: !user.is_collaborator,
        draft: false,
      })
      .then((submissionData) => {
        setToast({
          show: true,
          status: 'success',
          title: t('authoring.scorm.save.success'),
        });

        if (trackState) {
          setTrackState({
            ...trackState,
            contentId: submissionData._id,
            contentTitle: submissionData.title,
          });

          window.location.replace(`/pvt/#/edit/tracks/${trackState.track}`);

          return;
        }

        navigate(`/scorm/${submissionData._id}`);
      });

  if (id && isFetching) return <Loading />;

  if (isError) {
    console.error('Error Fetching Scorm', error);

    return (
      <ErrorAlert
        title="There was an error fetching the SCORM package"
        backAction={{
          to: '/pvt/#/overview/content',
          label: 'Back to content overview',
          isAngular: true,
        }}
      />
    );
  }

  return (
    <CreateTabs
      tabOptions={tabOptions}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      controls={
        <TabControls
          canAdvance={canAdvance}
          tabOptionsLength={tabOptions.length}
          onProceed={onProceed}
          canPublish={isValid && uploadStatus !== 'loading'}
          handlePublish={handlePublish}
        />
      }
    />
  );
}
